const NoteFound = () => {
    return (
        <main className="main_about">
            <div className="about">
                <div className="person_info">
                    <img className="person" src="https://i.imgur.com/JGEx3J8.png" alt=""/>
                    <p>Nobody's here...</p>
                </div>
            </div>
        </main>
    )
};

export default NoteFound;
